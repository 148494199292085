// this file was automatically generated, do not edit
/* eslint-disable */

export interface GetInterfaceByNameRequestHeaders {}

export interface GetInterfaceByNameRequestQuery {}

export interface GetInterfaceByNameRequestParams {
  name: string;
  version: string;
}

export interface GetInterfaceByNameRequestBody {}

export type GetInterfaceByNameInput = GetInterfaceByNameRequestBody & GetInterfaceByNameRequestHeaders & GetInterfaceByNameRequestQuery & GetInterfaceByNameRequestParams

export type GetInterfaceByNameRequest = {
  headers: GetInterfaceByNameRequestHeaders;
  query: GetInterfaceByNameRequestQuery;
  params: GetInterfaceByNameRequestParams;
  body: GetInterfaceByNameRequestBody;
}

export const parseReq = (input: GetInterfaceByNameInput): GetInterfaceByNameRequest & { path: string } => {
  return {
    path: `/v1/admin/interfaces/${encodeURIComponent(input['name'])}/${encodeURIComponent(input['version'])}`,
    headers: {  },
    query: {  },
    params: { 'name': input['name'], 'version': input['version'] },
    body: {  },
  }
}

export interface GetInterfaceByNameResponse {
  interface: {
    /**
     * ID of the [Interface](#schema_interface)
     */
    id: string;
    /**
     * Creation date of the [Interface](#schema_interface) in ISO 8601 format
     */
    createdAt: string;
    /**
     * Updating date of the [Interface](#schema_interface) in ISO 8601 format
     */
    updatedAt: string;
    /**
     * Name of the [Interface](#schema_interface)
     */
    name: string;
    /**
     * Version of the [Interface](#schema_interface)
     */
    version: string;
    entities: {
      /**
       * Entity definition
       */
      [k: string]: {
        /**
         * Title of the entity
         */
        title?: string;
        /**
         * Description of the entity
         */
        description?: string;
        schema: {
          [k: string]: any;
        };
      };
    };
    events: {
      /**
       * Event Definition
       */
      [k: string]: {
        /**
         * Title of the event
         */
        title?: string;
        /**
         * Description of the event
         */
        description?: string;
        schema: {
          [k: string]: any;
        };
      };
    };
    actions: {
      /**
       * Action definition
       */
      [k: string]: {
        /**
         * Title of the action
         */
        title?: string;
        /**
         * Description of the action
         */
        description?: string;
        billable?: boolean;
        cacheable?: boolean;
        input: {
          schema: {
            [k: string]: any;
          };
        };
        output: {
          schema: {
            [k: string]: any;
          };
        };
      };
    };
    channels: {
      [k: string]: {
        /**
         * Title of the channel
         */
        title?: string;
        /**
         * Description of the channel
         */
        description?: string;
        messages: {
          /**
           * Message definition
           */
          [k: string]: {
            schema: {
              [k: string]: any;
            };
          };
        };
      };
    };
    /**
     * Template string optionaly used at build time by integrations implementing this interface to pick a name for actions and events.
     */
    nameTemplate?: {
      script: string;
      language: string;
    };
  };
}

