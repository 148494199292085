import { format } from 'date-fns'
import * as React from 'react'
import { z } from 'zod'
import { Calendar } from '../elements'
import { IconButton, Input, Popover } from '~/elementsv2'
import { HiOutlineCalendar } from 'react-icons/hi2'

type DateInputProps = {
  value: Date
  onChange: (date: Date) => void
  onValidChanged?: (valid: boolean) => void
  fromDate?: Date
}

const dateSchema = z.coerce.date()

/**
 * @deprecated Components in /components and deprecated in favor of componentsV2 or directly from Radix/themes if not in componentsV2
 */
export const DateInput = ({ value, onChange, onValidChanged, fromDate }: DateInputProps) => {
  const [valid, setValid] = React.useState(true)
  const [open, setOpen] = React.useState(false)

  const [inputValue, setInputValue] = React.useState<string>(format(value, 'y-LL-dd HH:KK:mm'))

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const setValidAndNotify = (valid: boolean) => {
    setValid(valid)
    if (onValidChanged) {
      onValidChanged(valid)
    }
  }

  return (
    <>
      <Input
        onBlur={() => {
          if (valid) {
            onChange(dateSchema.parse(inputValue))
          }
        }}
        onChange={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const value = e.target.value.trim()
          if (!value) {
            setInputValue('')
            setValidAndNotify(false)
          } else {
            const result = dateSchema.safeParse(value)
            setValidAndNotify(result.success)
            setInputValue(value)
          }
        }}
        color={valid ? undefined : 'red'}
        value={inputValue}
      />
      <Popover
        open={open}
        onOpenChange={setOpen}
        trigger={<IconButton variant="minimal" color="gray" icon={HiOutlineCalendar} />}
        align="end"
      >
        <Calendar
          initialFocus
          mode="single"
          selected={value}
          onSelect={(date) => {
            if (date) {
              onChange(date)
              setValidAndNotify(true)
            } else {
              setValidAndNotify(false)
            }
            setInputValue(date ? format(date, 'y-LL-dd HH:KK:mm') : '')
            setOpen(false)
          }}
          defaultMonth={value}
          fromDate={fromDate}
        />
      </Popover>
    </>
  )
}
