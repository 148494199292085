import { IconButton as ThemeIconButton } from '@radix-ui/themes'
import { ComponentProps, FC, forwardRef } from 'react'
import cx from 'classnames'
import { cn } from '~/utils'

type Variant = ComponentProps<typeof ThemeIconButton>['variant'] | 'minimal'

type ButtonProps = { variant?: Variant; icon: FC<{ size?: number }> } & Omit<
  ComponentProps<typeof ThemeIconButton>,
  'variant' | 'children'
>

const getIconSize = (size: ButtonProps['size']) => {
  switch (size) {
    case '1':
      return 16
    case '2':
      return 20
    case '3':
      return 22
    case '4':
      return 24
    default:
      return 20
  }
}
1
const IconButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, className, icon: Icon, size = '2', ...props }, ref) => {
    if (variant === 'minimal') {
      return (
        <ThemeIconButton
          {...props}
          size={size}
          variant="outline"
          className={cx(
            className,
            'flex cursor-pointer items-center justify-center text-gray-11 shadow-none hover:bg-accent-3 hover:text-accent-11 disabled:opacity-50'
          )}
          ref={ref}
        >
          <Icon size={getIconSize(size)} />
        </ThemeIconButton>
      )
    }
    return (
      <ThemeIconButton {...props} variant={variant} size={size} className={cn('cursor-pointer', className)} ref={ref}>
        <Icon size={getIconSize(size)} />
      </ThemeIconButton>
    )
  }
)

export { IconButton }
