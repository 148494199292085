import { Outlet, createFileRoute, useNavigate } from '@tanstack/react-router'
import { IntegrationIcon } from '~/features/integrations/components'
import { Badge, Flex, Text } from '@radix-ui/themes'
import { Button, Icon, type MenuItem, DropdownMenu } from '~/elementsv2'
import { integrationQueryOptions, listWorkspaceIntegrationsQueryOptions } from '~/queries'
import { useCurrentRouteId } from '~/hooks'
import { PrivateIntegrationInstallPopover } from '~/features/integrations/components/PrivateIntegrationInstallPopover'
import {
  HiMiniChevronDown,
  HiMiniShieldCheck,
  HiOutlineChevronDown,
  HiOutlineCog6Tooth,
  HiOutlineListBullet,
  HiOutlineTag,
} from 'react-icons/hi2'
import { useSuspenseQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { setTag } from '@sentry/react'
import { isBotpressWorkspace } from '~/utils'
import type { ComponentProps } from 'react'
import { Page } from '~/componentsV2'

export const Route = createFileRoute('/workspaces/$workspaceId/integrations/$integrationId')({
  loader: ({ context, params: { integrationId, workspaceId } }) =>
    Promise.all([
      context.queryClient.ensureQueryData(listWorkspaceIntegrationsQueryOptions({ workspaceId })),
      context.queryClient.ensureQueryData(integrationQueryOptions({ integrationId, workspaceId })),
    ]),
  component: () => <IntegrationComponent />,
})

const IntegrationComponent = () => {
  const currentRouteId = useCurrentRouteId()
  const { integrationId, workspaceId } = Route.useParams()
  const integration = useSuspenseQuery(integrationQueryOptions({ integrationId, workspaceId })).data
  const nVersions = useSuspenseQuery(listWorkspaceIntegrationsQueryOptions({ workspaceId })).data.filter(
    (i) => i.name === integration.name
  ).length
  const integrations = _(useSuspenseQuery(listWorkspaceIntegrationsQueryOptions({ workspaceId })).data)
    .groupBy('name')
    .mapValues((ints) => ints[0]!)
    .values()
    .value()

  const navigate = useNavigate({ from: currentRouteId })

  setTag('integrationId', integrationId)

  const items: ComponentProps<typeof Page>['navbarItems'] = [
    {
      children: 'Details',
      to: '/workspaces/$workspaceId/integrations/$integrationId',
      active: currentRouteId === '/workspaces/$workspaceId/integrations/$integrationId/',
    },
    {
      leading: <Icon color="gray" icon={HiOutlineListBullet} />,
      children: 'Logs',
      to: '/workspaces/$workspaceId/integrations/$integrationId/logs',
      active: currentRouteId === '/workspaces/$workspaceId/integrations/$integrationId/logs',
    },
    {
      leading: <Icon color="gray" icon={HiOutlineTag} />,
      children: `${nVersions > 1 ? nVersions : ''} Versions`,
      to: '/workspaces/$workspaceId/integrations/$integrationId/versions',
      active: currentRouteId === '/workspaces/$workspaceId/integrations/$integrationId/versions',
    },
    {
      children: 'Settings',
      leading: <Icon color="gray" icon={HiOutlineCog6Tooth} />,
      to: '/workspaces/$workspaceId/integrations/$integrationId/settings',
      active: currentRouteId === '/workspaces/$workspaceId/integrations/$integrationId/settings',
    },
  ]

  const integrationSelectMenuItems: MenuItem[] = integrations.map(({ name, id, iconUrl }) => ({
    type: 'item',
    content: name,
    leadingIcon: <IntegrationIcon className="size-4" iconUrl={iconUrl} />,
    onSelect: () => void navigate({ to: currentRouteId, params: { integrationId: id } }),
  }))

  return (
    <Page
      title={
        <DropdownMenu color="gray" className="min-w-52" content={integrationSelectMenuItems}>
          <Button variant="ghost" color="gray" highContrast>
            <Flex align={'center'} gap={'2'}>
              <IntegrationIcon className="size-7" iconUrl={integration.iconUrl} />
              <Flex align={'baseline'} gap={'2'}>
                <Text size={'6'} weight={'bold'}>
                  {integration.name}
                </Text>
                <Text size={'1'} weight={'regular'} color={'gray'} className="italic">
                  {integration.version}
                </Text>
              </Flex>
              {integration.verificationStatus === 'approved' ||
                (isBotpressWorkspace(workspaceId) && (
                  <Badge color="green" className="ml-2" size={'1'}>
                    Verified <Icon size="1" icon={HiMiniShieldCheck} />
                  </Badge>
                ))}
              <Icon color="gray" icon={HiMiniChevronDown} />
            </Flex>
          </Button>
        </DropdownMenu>
      }
      actions={
        integration ? (
          <PrivateIntegrationInstallPopover currentIntegration={integration} workspaceId={workspaceId}>
            <Button trailing={<HiOutlineChevronDown className="h-4" />}>Install</Button>
          </PrivateIntegrationInstallPopover>
        ) : null
      }
      navbarItems={items}
    >
      <Outlet />
    </Page>
  )
}
