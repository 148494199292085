import { Badge, Box, Flex, Separator, Text } from '@radix-ui/themes'
import { useParams } from '@tanstack/react-router'
import {
  HiMiniChevronDown,
  HiOutlineArrowTrendingUp,
  HiOutlineChartBar,
  HiOutlineChatBubbleLeft,
  HiOutlineChatBubbleLeftRight,
  HiOutlineCog6Tooth,
  HiOutlineCommandLine,
  HiOutlineComputerDesktop,
  HiOutlineCubeTransparent,
  HiOutlineExclamationTriangle,
  HiOutlineHome,
  HiOutlineUsers,
  HiOutlineVariable,
} from 'react-icons/hi2'
import { type NavbarItem, Navbar, Boundrary, BotSelectorMenu, BotIcon } from '~/componentsV2'
import { Icon } from '~/elementsv2'
import { NotificationBadge } from '~/features/issues/componentsV2'
import { useCurrentRouteId } from '~/hooks'
import { useQuery } from '~/services'
import { useLocalStore } from '~/stores'
import { cn } from '~/utils'

type Props = { className?: string }

export const SideNav = ({ className, ...props }: Props) => {
  const { workspaceId, botId } = useParams({ strict: false })

  const routeId = useCurrentRouteId()

  const workspaceNavbarItems: NavbarItem[] = [
    { text: 'Home', leadingIcon: HiOutlineHome, to: '/workspaces/$workspaceId/home' },
    { text: 'Your Integrations', leadingIcon: HiOutlineCubeTransparent, to: '/workspaces/$workspaceId/integrations' },
    { text: 'Usage', leadingIcon: HiOutlineChartBar, to: '/workspaces/$workspaceId/usage' },
    { text: 'Settings', leadingIcon: HiOutlineCog6Tooth, to: '/workspaces/$workspaceId/settings' },
  ].map((item) => {
    return { ...item, active: routeId?.includes(item.to) } as NavbarItem
  })

  const botNavbarItems: NavbarItem[] = [
    { text: 'Overview', leadingIcon: HiOutlineHome, to: '/workspaces/$workspaceId/bots/$botId/overview' },
    {
      text: 'Webchat',
      leadingIcon: HiOutlineChatBubbleLeft,
      trailingItem: (
        <Badge color="green" variant="outline">
          New
        </Badge>
      ),
      to: '/workspaces/$workspaceId/bots/$botId/webchat',
    },
    { text: 'Logs', leadingIcon: HiOutlineCommandLine, to: '/workspaces/$workspaceId/bots/$botId/logs' },
    {
      text: 'Conversations',
      leadingIcon: HiOutlineChatBubbleLeftRight,
      to: '/workspaces/$workspaceId/bots/$botId/conversations',
    },
    { text: 'Analytics', leadingIcon: HiOutlineArrowTrendingUp, to: '/workspaces/$workspaceId/bots/$botId/analytics' },
    { text: 'Events', leadingIcon: HiOutlineComputerDesktop, to: '/workspaces/$workspaceId/bots/$botId/events' },
    {
      text: 'Issues',
      leadingIcon: HiOutlineExclamationTriangle,
      trailingItem: (
        <Boundrary loader={null} onError={() => null}>
          <NotificationBadge />
        </Boundrary>
      ),
      to: '/workspaces/$workspaceId/bots/$botId/issues',
    },
    {
      text: 'Configuration variables',
      leadingIcon: HiOutlineVariable,
      to: '/workspaces/$workspaceId/bots/$botId/configuration-variables',
    },
    {
      text: 'HITL',
      leadingIcon: HiOutlineUsers,
      trailingItem: (
        <Badge color="green" variant="outline">
          New
        </Badge>
      ),
      to: '/workspaces/$workspaceId/bots/$botId/hitl',
    },
  ].map((item) => {
    return { ...item, active: routeId?.includes(item.to) } as NavbarItem
  })

  const lastBotId = useLocalStore((state) => state.getLastActiveBotId(workspaceId))
  const navBotId = botId ?? lastBotId
  const { data: bots } = useQuery('workspaces_/$workspaceId_/bots_', { workspaceId: workspaceId ?? '' })

  const { data: bot } = useQuery('workspaces_/$workspaceId_/bots_/$botId_', {
    workspaceId: workspaceId ?? '',
    botId: navBotId ?? '',
  })

  const hasBots = (bots?.length ?? 0) > 0

  return (
    <Flex direction={'column'} p={'2'} pt={'0'} {...props} className={cn(className)}>
      {workspaceId && (
        <>
          <Navbar workspaceId={workspaceId} items={workspaceNavbarItems} direction="column" />
          <Box p={'3'}>
            <Separator orientation={'horizontal'} size={'4'} />
          </Box>

          <BotSelectorMenu bots={bots ?? []}>
            <Flex
              gap={'2'}
              px={'3'}
              py={'2'}
              my={'-1'}
              className={cn(hasBots ? 'rounded hover:bg-gray-3' : 'cursor-not-allowed opacity-70')}
            >
              {bot?.id && <BotIcon id={bot.id} size="1" />}
              <Text color="gray" size={'1'}>
                {bot?.name ? bot.name : hasBots ? 'Select a bot...' : 'No bots in workspace'}
              </Text>
              {hasBots && <Icon className="ml-auto" color="gray" icon={HiMiniChevronDown} />}
            </Flex>
          </BotSelectorMenu>
          {!!bot && <Navbar workspaceId={workspaceId} botId={navBotId} items={botNavbarItems} direction="column" />}
        </>
      )}
    </Flex>
  )
}
