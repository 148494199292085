// this file was automatically generated, do not edit
/* eslint-disable */

export interface UpdateIntegrationRequestHeaders {}

export interface UpdateIntegrationRequestQuery {}

export interface UpdateIntegrationRequestParams {
  id: string;
}

export interface UpdateIntegrationRequestBody {
  /**
   * Default configuration definition of the integration
   */
  configuration?: {
    /**
     * Title of the configuration
     */
    title?: string;
    /**
     * Description of the configuration
     */
    description?: string;
    /**
     * Schema of the configuration in the `JSON schema` format. The configuration data is validated against this `JSON schema`.
     */
    schema?: {
      [k: string]: any;
    };
    identifier?: {
      linkTemplateScript?: string | null;
      required?: boolean;
    };
  };
  /**
   * Additional configuration definitions of the integration
   */
  configurations?: {
    [k: string]: {
      /**
       * Title of the configuration
       */
      title?: string;
      /**
       * Description of the configuration
       */
      description?: string;
      /**
       * Schema of the configuration in the `JSON schema` format. The configuration data is validated against this `JSON schema`.
       */
      schema?: {
        [k: string]: any;
      };
      identifier?: {
        linkTemplateScript?: string | null;
        required?: boolean;
      };
    } | null;
  };
  channels?: {
    [k: string]: {
      /**
       * Title of the channel
       */
      title?: string;
      /**
       * Description of the channel
       */
      description?: string;
      messages?: {
        /**
         * Message definition
         */
        [k: string]: {
          schema: {
            [k: string]: any;
          };
        } | null;
      };
      conversation?: {
        /**
         * The conversation creation setting determines how to create a conversation through the API directly. The integration will have to implement the `createConversation` functionality to support this setting.
         */
        creation?: {
          /**
           * Enable conversation creation
           */
          enabled: boolean;
          /**
           * The list of tags that are required to be specified when calling the API directly to create a conversation.
           */
          requiredTags: string[];
        };
        tags?: {
          /**
           * Definition of a tag that can be provided on the object
           */
          [k: string]: {
            /**
             * Title of the tag
             */
            title?: string;
            /**
             * Description of the tag
             */
            description?: string;
          } | null;
        };
      };
      message?: {
        tags?: {
          /**
           * Definition of a tag that can be provided on the object
           */
          [k: string]: {
            /**
             * Title of the tag
             */
            title?: string;
            /**
             * Description of the tag
             */
            description?: string;
          } | null;
        };
      };
    } | null;
  };
  identifier?: {
    extractScript?: string | null;
    fallbackHandlerScript?: string | null;
  };
  actions?: {
    /**
     * Action definition
     */
    [k: string]: {
      /**
       * Title of the action
       */
      title?: string;
      /**
       * Description of the action
       */
      description?: string;
      billable?: boolean;
      cacheable?: boolean;
      input: {
        schema: {
          [k: string]: any;
        };
      };
      output: {
        schema: {
          [k: string]: any;
        };
      };
    } | null;
  };
  events?: {
    /**
     * Event Definition
     */
    [k: string]: {
      /**
       * Title of the event
       */
      title?: string;
      /**
       * Description of the event
       */
      description?: string;
      schema: {
        [k: string]: any;
      };
    } | null;
  };
  states?: {
    /**
     * State definition
     */
    [k: string]: {
      /**
       * Type of the [State](#schema_state) (`conversation`, `user` or `integration`)
       */
      type: "conversation" | "user" | "integration";
      /**
       * Schema of the [State](#schema_state) in the `JSON schema` format. This `JSON schema` is going to be used for validating the state data.
       */
      schema: {
        [k: string]: any;
      };
    } | null;
  };
  user?: {
    /**
     * The user creation setting determines how to create a user through the API directly. The integration will have to implement the `createUser` functionality to support this setting.
     */
    creation?: {
      /**
       * Enable user creation
       */
      enabled: boolean;
      /**
       * The list of tags that are required to be specified when calling the API directly to create a user.
       */
      requiredTags: string[];
    };
    tags?: {
      /**
       * Definition of a tag that can be provided on the object
       */
      [k: string]: {
        /**
         * Title of the tag
         */
        title?: string;
        /**
         * Description of the tag
         */
        description?: string;
      } | null;
    };
  };
  entities?: {
    /**
     * Entity definition
     */
    [k: string]: {
      /**
       * Title of the entity
       */
      title?: string;
      /**
       * Description of the entity
       */
      description?: string;
      schema: {
        [k: string]: any;
      };
    } | null;
  };
  interfaces?: {
    [k: string]: {
      /**
       * ID of the interface
       */
      id: string;
      entities?: {
        [k: string]: {
          name: string;
        };
      };
      actions?: {
        [k: string]: {
          name: string;
        };
      };
      events?: {
        [k: string]: {
          name: string;
        };
      };
      channels?: {
        [k: string]: {
          name: string;
        };
      };
    } | null;
  };
  /**
   * Secrets are integration-wide values available in the code via environment variables formatted with a SECRET_ prefix followed by your secret name. A secret name must respect SCREAMING_SNAKE casing.
   */
  secrets?: {
    [k: string]: string | null;
  };
  /**
   * JavaScript code of the integration
   */
  code?: string;
  /**
   * Base64 encoded svg of the integration icon. This icon is global to the integration each versions will be updated when this changes.
   */
  icon?: string;
  /**
   * Base64 encoded markdown of the integration readme. The readme is specific to each integration versions.
   */
  readme?: string;
  /**
   * Title of the integration. This is the name that will be displayed in the UI
   */
  title?: string;
  /**
   * Description of the integration. This is the description that will be displayed in the UI
   */
  description?: string;
  /**
   * URL of the integration
   */
  url?: string | null;
  /**
   * Idicates if the integration is public. Public integrations are available to all and cannot be updated without creating a new version.
   */
  public?: boolean;
}

export type UpdateIntegrationInput = UpdateIntegrationRequestBody & UpdateIntegrationRequestHeaders & UpdateIntegrationRequestQuery & UpdateIntegrationRequestParams

export type UpdateIntegrationRequest = {
  headers: UpdateIntegrationRequestHeaders;
  query: UpdateIntegrationRequestQuery;
  params: UpdateIntegrationRequestParams;
  body: UpdateIntegrationRequestBody;
}

export const parseReq = (input: UpdateIntegrationInput): UpdateIntegrationRequest & { path: string } => {
  return {
    path: `/v1/admin/integrations/${encodeURIComponent(input['id'])}`,
    headers: {  },
    query: {  },
    params: { 'id': input['id'] },
    body: { 'configuration': input['configuration'], 'configurations': input['configurations'], 'channels': input['channels'], 'identifier': input['identifier'], 'actions': input['actions'], 'events': input['events'], 'states': input['states'], 'user': input['user'], 'entities': input['entities'], 'interfaces': input['interfaces'], 'secrets': input['secrets'], 'code': input['code'], 'icon': input['icon'], 'readme': input['readme'], 'title': input['title'], 'description': input['description'], 'url': input['url'], 'public': input['public'] },
  }
}

export interface UpdateIntegrationResponse {
  integration: {
    /**
     * ID of the [Integration](#schema_integration)
     */
    id: string;
    /**
     * Creation date of the [Integration](#schema_integration) in ISO 8601 format
     */
    createdAt: string;
    /**
     * Updating date of the [Integration](#schema_integration) in ISO 8601 format
     */
    updatedAt: string;
    /**
     * Global identifier configuration of the [Integration](#schema_integration)
     */
    identifier: {
      /**
       * VRL Script of the [Integration](#schema_integration) to handle incoming requests for a request that doesn't have an identifier
       */
      fallbackHandlerScript?: string;
      /**
       * VRL Script of the [Integration](#schema_integration) to extract the identifier from an incoming webhook often use for OAuth
       */
      extractScript?: string;
    };
    /**
     * URL of the [Integration](#schema_integration)
     */
    url: string;
    /**
     * Name of the [Integration](#schema_integration)
     */
    name: string;
    /**
     * Version of the [Integration](#schema_integration)
     */
    version: string;
    interfaces: {
      [k: string]: {
        /**
         * ID of the interface
         */
        id: string;
        /**
         * Name of the interface
         */
        name: string;
        entities: {
          [k: string]: {
            name: string;
          };
        };
        actions: {
          [k: string]: {
            name: string;
          };
        };
        events: {
          [k: string]: {
            name: string;
          };
        };
        channels: {
          [k: string]: {
            name: string;
          };
        };
      };
    };
    /**
     * Configuration definition
     */
    configuration: {
      /**
       * Title of the configuration
       */
      title?: string;
      /**
       * Description of the configuration
       */
      description?: string;
      /**
       * Identifier configuration of the [Integration](#schema_integration)
       */
      identifier: {
        linkTemplateScript?: string;
        required: boolean;
      };
      /**
       * Schema of the configuration in the `JSON schema` format. The configuration data is validated against this `JSON schema`.
       */
      schema?: {
        [k: string]: any;
      };
    };
    configurations: {
      /**
       * Configuration definition
       */
      [k: string]: {
        /**
         * Title of the configuration
         */
        title?: string;
        /**
         * Description of the configuration
         */
        description?: string;
        /**
         * Identifier configuration of the [Integration](#schema_integration)
         */
        identifier: {
          linkTemplateScript?: string;
          required: boolean;
        };
        /**
         * Schema of the configuration in the `JSON schema` format. The configuration data is validated against this `JSON schema`.
         */
        schema?: {
          [k: string]: any;
        };
      };
    };
    channels: {
      /**
       * Channel definition
       */
      [k: string]: {
        /**
         * Title of the channel
         */
        title?: string;
        /**
         * Description of the channel
         */
        description?: string;
        messages: {
          /**
           * Message definition
           */
          [k: string]: {
            schema: {
              [k: string]: any;
            };
          };
        };
        /**
         * Conversation object configuration
         */
        conversation: {
          tags: {
            /**
             * Definition of a tag that can be provided on the object
             */
            [k: string]: {
              /**
               * Title of the tag
               */
              title?: string;
              /**
               * Description of the tag
               */
              description?: string;
            };
          };
          /**
           * The conversation creation setting determines how to create a conversation through the API directly. The integration will have to implement the `createConversation` functionality to support this setting.
           */
          creation: {
            /**
             * Enable conversation creation
             */
            enabled: boolean;
            /**
             * The list of tags that are required to be specified when calling the API directly to create a conversation.
             */
            requiredTags: string[];
          };
        };
        /**
         * Message object configuration
         */
        message: {
          tags: {
            /**
             * Definition of a tag that can be provided on the object
             */
            [k: string]: {
              /**
               * Title of the tag
               */
              title?: string;
              /**
               * Description of the tag
               */
              description?: string;
            };
          };
        };
      };
    };
    states: {
      /**
       * State definition
       */
      [k: string]: {
        /**
         * Type of the [State](#schema_state) (`conversation`, `user` or `integration`)
         */
        type: "conversation" | "user" | "integration";
        /**
         * Schema of the [State](#schema_state) in the `JSON schema` format. This `JSON schema` is going to be used for validating the state data.
         */
        schema: {
          [k: string]: any;
        };
      };
    };
    events: {
      /**
       * Event Definition
       */
      [k: string]: {
        /**
         * Title of the event
         */
        title?: string;
        /**
         * Description of the event
         */
        description?: string;
        schema: {
          [k: string]: any;
        };
      };
    };
    actions: {
      /**
       * Action definition
       */
      [k: string]: {
        /**
         * Title of the action
         */
        title?: string;
        /**
         * Description of the action
         */
        description?: string;
        billable?: boolean;
        cacheable?: boolean;
        input: {
          schema: {
            [k: string]: any;
          };
        };
        output: {
          schema: {
            [k: string]: any;
          };
        };
      };
    };
    /**
     * User object configuration
     */
    user: {
      tags: {
        /**
         * Definition of a tag that can be provided on the object
         */
        [k: string]: {
          /**
           * Title of the tag
           */
          title?: string;
          /**
           * Description of the tag
           */
          description?: string;
        };
      };
      /**
       * The user creation setting determines how to create a user through the API directly. The integration will have to implement the `createUser` functionality to support this setting.
       */
      creation: {
        /**
         * Enable user creation
         */
        enabled: boolean;
        /**
         * The list of tags that are required to be specified when calling the API directly to create a user.
         */
        requiredTags: string[];
      };
    };
    entities: {
      /**
       * Entity definition
       */
      [k: string]: {
        /**
         * Title of the entity
         */
        title?: string;
        /**
         * Description of the entity
         */
        description?: string;
        schema: {
          [k: string]: any;
        };
      };
    };
    /**
     * Indicates if the integration is a development integration; Dev integrations run locally
     */
    dev: boolean;
    /**
     * Title of the integration. This is the name that will be displayed in the UI
     */
    title: string;
    /**
     * Description of the integration. This is the description that will be displayed in the UI
     */
    description: string;
    /**
     * URL of the icon of the integration. This is the icon that will be displayed in the UI
     */
    iconUrl: string;
    /**
     * URL of the readme of the integration. This is the readme that will be displayed in the UI
     */
    readmeUrl: string;
    /**
     * Idicates if the integration is public. Public integrations are available to all and cannot be updated without creating a new version.
     */
    public: boolean;
    /**
     * Status of the integration version verification
     */
    verificationStatus: "unapproved" | "pending" | "approved" | "rejected";
    /**
     * Secrets are integration-wide values available in the code via environment variables formatted with a SECRET_ prefix followed by your secret name. A secret name must respect SCREAMING_SNAKE casing.
     */
    secrets: string[];
  };
}

