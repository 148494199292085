import { Dialog } from '@radix-ui/themes'
import React, { useState } from 'react'
import { HiOutlineMenu } from 'react-icons/hi'
import { IconButton } from '~/elementsv2'

function MobileSidebar({ open, close, children }: React.PropsWithChildren<{ open: boolean; close: () => void }>) {
  return (
    <Dialog.Root open={open} onOpenChange={close}>
      <Dialog.Content className="fixed inset-y-1 left-1 w-full max-w-80 p-2 ">{children}</Dialog.Content>
    </Dialog.Root>
  )
}

export function DrawerLayout({
  navbar,
  sidebar,
  children,
}: React.PropsWithChildren<{ navbar: React.ReactNode; sidebar: React.ReactNode }>) {
  const [showSidebar, setShowSidebar] = useState(false)

  return (
    <div className="relative isolate flex min-h-svh w-full bg-white dark:bg-gray-1 max-lg:flex-col lg:bg-gray-2 ">
      {/* Sidebar on desktop */}
      <div className="fixed inset-0 top-14 w-64 max-lg:hidden">{sidebar}</div>

      {/* Sidebar on mobile */}
      <MobileSidebar open={showSidebar} close={() => setShowSidebar(false)}>
        {sidebar}
      </MobileSidebar>

      {/* Navbar on desktop */}
      <header className="fixed inset-x-0 top-0 hidden h-14 items-center px-4 lg:flex">
        <div className="min-w-0 flex-1">{navbar}</div>
      </header>

      {/* Navbar on mobile */}
      <header className="flex items-center gap-2 px-2 lg:hidden">
        <div className="py-2.5">
          <IconButton
            variant="minimal"
            color="gray"
            icon={HiOutlineMenu}
            onClick={() => setShowSidebar(true)}
            aria-label="Open navigation"
          />
        </div>
        <div className="min-w-0 flex-1">{navbar}</div>
      </header>

      {/* Content */}
      <main className="fixed bottom-2 left-2 right-2 top-14 overflow-auto overflow-y-scroll lg:left-64 lg:rounded-lg lg:bg-white lg:p-8 lg:shadow-sm lg:ring-1 lg:ring-gray-4 dark:lg:bg-gray-2 ">
        <div className="mx-auto">{children}</div>
      </main>
    </div>
  )
}
