import { JsonFormsCore } from '@jsonforms/core'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import { Button, Pane, ScrollArea } from '../../../elements'
import { useUpdateBot } from '../../../hooks'
import { showSuccessToast } from '../../../services'
import { JsonForm } from '../../json-forms/components'
import { validateConfigurationData } from '../services'
import { BotObject } from '../types'
import { Permission } from '../../users/types'
import { trackEvent } from '../../../providers'
import { EmptyState } from '~/elementsv2'
import Variable from '~/assets/emptyStates/variable.svg?react'

type Props = {
  workspaceId: string
  bot: BotObject
  authorize: (permission: Permission) => boolean | undefined
}

export function BotConfigurationVariables({ bot, workspaceId, authorize }: Props): JSX.Element {
  const userCanEdit = authorize('bots.update')
  const [formErrors, setFormErrors] = useState<JsonFormsCore['errors']>([])
  const [configurations, setConfigurations] = useState(validateConfigurationData(bot.configuration))
  const { mutate: updateBot, isPending } = useUpdateBot()
  useEffect(() => {
    setConfigurations(validateConfigurationData(bot.configuration))
  }, [bot.configuration])

  const updateBotConfigs = () => {
    updateBot({
      id: bot.id,
      workspaceId,
      configuration: { data: configurations },
      onSuccess: () => {
        showSuccessToast('The configuration variables have been updated successfully.')
      },
    })
  }
  const configurationKeys = Object.keys(bot.configuration.schema?.properties ?? {})
  return configurationKeys.length > 0 ? (
    <Pane className={cx('relative mx-auto  w-full max-w-2xl rounded-md')}>
      <ScrollArea>
        <Pane.Body>
          <div className="flex flex-col overflow-hidden">
            <JsonForm
              onChange={({ data, errors }) => {
                setFormErrors(errors)
                setConfigurations(data)
              }}
              readonly={!userCanEdit}
              schema={bot.configuration.schema}
              data={configurations}
            />
          </div>
        </Pane.Body>
        {/* Crossfield errors only; Errors bound to fields are shown by the renderers in JsonForm */}
        {formErrors?.map(
          (error, i) =>
            !error.instancePath && (
              <div className="mx-6 mb-2 rounded-md bg-red-100 p-2 px-4 text-xs text-red-700" key={i}>
                {error.message}
              </div>
            )
        )}
      </ScrollArea>

      {userCanEdit && (
        <Pane.Footer className="bottom-0 flex justify-end">
          <Button
            size="small"
            className="w-fit"
            disabled={formErrors?.length !== 0}
            onClick={() => {
              void updateBotConfigs()
              trackEvent({ type: 'update_configuration_variables' })
            }}
          >
            {isPending ? 'Saving...' : 'Save'}
          </Button>
        </Pane.Footer>
      )}
    </Pane>
  ) : (
    <div>
      <EmptyState
        title="No configuration variables found"
        description="Add configuration variables to your chatbot definition to set them here and use them in your bot's logic."
        iconSize={10}
        icon={Variable}
      />
    </div>
  )
}
